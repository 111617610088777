import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Downloader from "../components/downloader"
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby'
import Faqs from "../components/faqs"
import HowTo from "../components/howTo"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const IndexPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Seo title={t("Online Youtube Video Downloader")} description={t("Download Youtube Videos in HD and Audio Formats")} />
      <Downloader title={t("Online Free Youtube Video Downloader")} description={t("Download Youtube Videos in HD and Audio Formats")} />
      <Container>
        <Row>
          <Col>
            <h2 className="text-center pt-5 pb-2">{t("Youtube Video Downloader")}</h2>
            <p>{t("A well-known video-sharing website called Youtube has videos in almost every genre.")} <Link to="/youtube-video-downloader/">{t("Youtube Video Downloader Free")}</Link> {t("is your best option if you come across a video on this platform and want to save it for later viewing without having to access the internet. Simply copy the video's URL, paste it into this video downloader, and press the download button to download Youtube videos. This tool will process your request, and you'll instantly receive the desired video in your device's local storage.")}</p>
            <p>{t("There are many websites and apps that claim to be able to download YouTube videos, but it's important to be aware that downloading videos from YouTube is generally a violation of the website's terms of service, and can also be a violation of copyright law.")}</p>
            <p>{t("If you want to download a YouTube video, it's important to make sure you have permission from the copyright holder, or that the video is in the public domain or covered by a Creative Commons license that allows for downloading and reuse.")}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="text-center pt-5 pb-2">{t("How To Download Youtube Videos Online")}?</h2>
            <p>{t("If you do have permission to download a YouTube video, or if the video is in the public domain or covered by a Creative Commons license, there are a few options you can try")}:</p>
            <ol>
              <li>{t("Use a video downloader tool or browser extension. There are many tools available online that claim to be able to download YouTube videos, but it's important to be aware that the use of these tools may be a violation of YouTube's terms of service and can also be a violation of copyright law. e.g")}: <Link to="/youtube-video-downloader/">{t("Youtube Video Downloader Free")}</Link></li>
              <li>{t("Use a screen recording tool. Some screen recording tools allow you to record a video that is playing on your computer or device, which can be used to save a copy of the YouTube video.")}</li>
              <li>{t("Download the video from a third-party website. There are some websites that offer downloads of YouTube videos, but it's important to be aware that these websites may be hosting copyrighted material without permission, and the use of these websites may be a violation of copyright law.")}</li>
            </ol>
            <p>{t("It's important to note that downloading YouTube videos without permission from the copyright holder is generally not legal and can result in copyright infringement. If you want to download a YouTube video, it's important to make sure you have the necessary permissions or that the video is in the public domain or covered by a Creative Commons license.")}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="text-center pt-5 pb-2">{t("Questions About Youtube Video Download")}</h2>
            <h5>{t("Is downloading youtube videos illegal")}?</h5>
            <p>{t("Yes, technically, it is illegal to download Youtube videos if you are not the owner of the video or if you do not have permission from the owner about the rights of the video or unless you own the video itself. All youtube videos are the property of their respective owners.")}</p>
            <h5>{t("Are there any exceptions to this rule")}?</h5>
            <p>{t("There are a few exceptions to the rule that downloading YouTube videos is generally not legal. For example, if a YouTube video is in the public domain or covered by a Creative Commons license that allows for downloading and reuse, you may be able to download the video legally. However, it's important to be sure that you are complying with the specific terms of the Creative Commons license and giving proper attribution to the copyright holder.")}</p>
            <h5>{t("How can I download a YouTube video legally")}?</h5>
            <p>{t("If you want to download a YouTube video legally, you will need to obtain permission from the copyright holder or ensure that the video is in the public domain or covered by a Creative Commons license that allows for downloading and reuse. In some cases, the copyright holder may allow you to download the video for personal use, but may not allow you to distribute the video or use it for commercial purposes.")}</p>
            <h5>{t("Are there any tools or websites that can help me download YouTube videos")}?</h5>
            <p>{t("There are many tools and websites that claim to be able to download YouTube videos, but it's important to be aware that the use of these tools may be a violation of YouTube's terms of service and can also be a violation of copyright law. If you do decide to use one of these tools, it's important to make sure you have the necessary permissions or that the video is in the public domain or covered by a Creative Commons license.")}</p>
            <h5>{t("Where do downloaded youtube videos go")}?</h5>
            <p>{t("The downloaded videos can be found in the download folders of your mobile or the PC/laptop where you normally get your downloaded files.")}</p>
            <h5>{t("Can I download Youtube videos on Mac")}?</h5>
            <p>{t("Yes, this youtube video downloader also works for Mac and all operating systems unless you have the rights to download that video. We honour the content policy of all the owners.")}</p>
          </Col>
        </Row>
      </Container>
      <HowTo />
      <Faqs />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
